import { useNavigate, Outlet } from 'react-router-dom';
import { Grid } from 'antd';
import styled from 'styled-components';
import Footer from './Footer';

const { useBreakpoint } = Grid;

const Container = styled.div`
    width: 100%;
    background-color: rgb(6 6 16);
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 10px;
    }
       
    &::-webkit-scrollbar-thumb {
        background: #130a08; 
        border-radius: 5px;
    }
`;

const Structure = ({}) => {

    const screens = useBreakpoint();

    return(
        <Container>
            <div
                style={{
                    padding: "0px 24px",
                    margin: 0,
                    minHeight: "100vh",
                }}
                >
                <Outlet context={{ screens }}/>
            </div>
            <Footer screens={screens}/>
        </Container>
    );
};

export default Structure;