import './App.css';
import React, {Suspense} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Structure from './Structure';
import SuspenseLoader from './components/SuspenseLoader';
const SingleView = React.lazy(() => import('./SingleView'));



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Suspense fallback={<SuspenseLoader/>}> <Structure/> </Suspense>} path="/">
          <Route element={<Suspense fallback={<SuspenseLoader/>}> <SingleView/> </Suspense>} path="/"/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
