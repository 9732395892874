import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

const Container = styled.div`
    width: 100%;
    box-shadow: 0px 0px 10px rgb(255 183 0 / 39%);
    position: fixed;
    bottom: 0;
`;
const FlexBox = styled.div`
    width: 100%;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    background-color: rgb(6 6 16);
    padding: 10px 0px;
    border-top: 2px solid rgba(219, 183, 0, 0.39);
`;

const Page = styled.div`
    width: fit-content;
    display: flex;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    border-right: 2px solid rgb(219 183 0 / 39%);
    color: ${({ selected }) =>
    selected ? 'rgb(219 183 0);' : '#fff'};
`;


function isElementInViewport (el) {

    if(el){
        var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
    }
    
}

const menuPositions = [
    {
        key: "home",
        position: 0
    },
    {
        key: "skills",
        position: 250
    },
    {
        key: "projects",
        position: 
            window.innerWidth > 1200 ? 
                800 
            : window.innerWidth > 836 ? 
                1000
            : window.innerWidth > 802 ? 
                1450
            :
                1800
    },
    {
        key: "contact",
        position: 
            window.innerWidth > 1200 ? 
                2200 
            : window.innerWidth > 836 ? 
                2500
            : window.innerWidth > 802 ? 
                2900
            :
                4600
    },
];

const Footer = ({screens}) => {
    const navigate = useNavigate();
    const [currentMenu,SetCurMenu] = useState('home');

    useEffect(() => {
        window.addEventListener("scroll",() => {
            for(const idx in menuPositions){
                const menu = menuPositions[idx];
                const offset = window.pageYOffset;

                if(idx==menuPositions.length-1 && offset >= menu.position) {
                    SetCurMenu(menu.key)
                }
                else if (idx < menuPositions.length-1 && offset >= menu.position){
                    SetCurMenu(menu.key)
                }
            }
      })
    }, []);
    
    const scroll = (id) => {
        const title = document.getElementById(id);
        title.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return(
        <Container>
            <FlexBox>
                <Page selected={currentMenu=='home'} onClick={()=>scroll('home')}>
                    Home
                </Page>
                <Page selected={currentMenu=='skills'} onClick={()=>scroll('skills')}>
                    Skills
                </Page>
                <Page selected={currentMenu=='projects'} onClick={()=>scroll('projects')}>
                    Projects
                </Page>
                <Page selected={currentMenu=='contact'} onClick={()=>scroll('contact')} style={{borderRight: "unset"}}>
                    Contact
                </Page>
            </FlexBox>
        </Container>
    );
};

export default Footer;